@font-face {
  font-family: "LilitaOne";
  src: local("LilitaOne-Regular"),
   url("./fonts/LilitaOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "CarterOne";
  src: local("CarterOne-Regular"),
   url("./fonts/CarterOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Bangers";
  src: local("Bangers-Regular"),
   url("./fonts/Bangers-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RammettoOne";
  src: local("RammettoOne-Regular"),
   url("./fonts/RammettoOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Chango";
  src: local("Chango-Regular"),
   url("./fonts/Chango-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ReadexPro";
  src: local("ReadexPro-Medium"),
   url("./fonts/ReadexPro-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Bungee";
  src: local("Bungee-Regular"),
   url("./fonts/Bungee-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilory-Bold"),
   url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.map-container {
  width: 50%;
  height: 50vh;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.create-recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}

.create-recipe form {
  display: flex;
  flex-direction: column;
}

.white { color: white; }

.blue { color: rgb(142, 227, 255); }

.red { color: red; }

.flex {
  display: flex;
  justify-content: center;
}

.chango { font-family: 'Chango', cursive; }

.bangers { font-family: "Bangers"; }

.carterone { font-family: "CarterOne"; }

.rammettoone { font-family: "RammettoOne"; } 

.readexpro { font-family: "ReadexPro"; }

.italic { font-style: italic; }

.bold { font-weight: bold; }

.loading {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 120px;
  height: 1000px;
  background-color: rgb(248, 105, 105);
  background-image: url(./images/ghost-background1.png);
}

@media (max-width: 767px) {
  .loading {
    display: block;
    text-align: left;
    height: 600px;
    font-size: 50px;
    color: black;
    background-color: white;
    background-image: url(./images/glasses_ghost.png);
    background-size: cover;
  }
}




