.home-community {
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: center;
}

.community-example {
  height: 250px;
  width: 200px;
}

.community-section {
  background: rgb(27, 27, 27);
  padding: 0% 1% 1% 1%;
  margin: 2% 5% 0 5%;
  width: 20%;
}

.community-section:hover .community-example {
  content: url("../../images/glasses_ghost.png");
}

.ct1 { font-size: 40px; } 

.left-popin { animation: leftPopInAnimation 0.5s; }

@keyframes leftPopInAnimation {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .home-community {
    margin-top: 75px;
    align-items: center;
  }

  .community-section {
    display: flex;
    flex-direction: column;
    padding: 5%;
    margin: 5% 10% 10% 15%;
    width: 250px;
    align-items: center;
  }

  .ct1 { font-size: 30px; }

  .community-example {
    height: 150px;
    width: 90px;
  }

  .flex {
    flex-direction: column;
    justify-content: center;
  }
}

