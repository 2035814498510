.home-search {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-searchbar {
  font-size: 30px;
  border: 5px solid black;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  width: 650px;
}

.home-search-submit {
  font-size: 40px;
  background-color: black;
  border: none;
  color: red;
  padding: 10px;
}

@media screen and (max-width: 480px) {
  .home-searchbar {
    border: 3px solid black;
    font-size: 20px; 
    width: 92%; 
    margin-top: 0;
    margin-bottom: 10px; 
  }

  .home-search-submit {
    border-radius: 10px;
    font-size: 20px; 
    padding: 10px 20px; 
    width: 100%;
  }
}
