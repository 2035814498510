.home-featured {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(98,11,11,1) 100%);
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  padding-top: 5%;
  padding-bottom: 5%;
}

.hf-title {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  margin-bottom: 40px;
}

.rp2 { font-size: 65px; }

.each-slide-effect {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  width: 1000px;
  height: auto;
}

.more-reviews { margin-top: 30px; }

@media screen and (max-width: 480px) {
  .home-featured {
    padding-bottom: 50px;
  }

  .hf-title {
    margin: 0 10px 20px 10px;
  }

  .rp2 {
    font-size: 30px; 
  }

  .slide-image {
    width: 250px;
    height: auto;
  }

  .more-reviews {
    margin-top: 50px; 
  }
}
