.searchbar-container {
  width: 600px;
}

.searchbar-autocomplete {
  width: 100%;
  font-size: 35px;
}

.searchbar-input {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: 25px;
}

@media (max-width: 768px) {
  .searchbar-container {
    width: fit-content;
  }

  .searchbar-input {
    font-size: 18px;
  }

}
