.profile-tabs .react-tabs__tab-list {
  display: flex;
  background-color: white;
  border-bottom: 1px solid rgb(34, 34, 34);
  font-family: 'Readexpro';
  font-size: 30px;
  margin: 0;
  padding: 0;
}

.profile-tabs .react-tabs__tab--selected {
  background: red; 
  color: white; 
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
}

.profile-tabs .react-tabs__tab-panel {
  padding: 20px;
  background: black; 
  background-image: url('../images/ghost-background1.png');
  margin: 0;
  background-repeat: repeat;
}

.setting {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 15% 5% 15%;
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
}

.edit-review {
  font-family: 'ReadexPro';
  font-size: 20px;
  width: 1000px;
  height: 300px;
}

@media (max-width: 767px) {
 .setting {
  margin: 0 0 10% 0;
 }

 .edit-review {
  font-family: 'ReadexPro';
  font-size: 15px;
  width: 95%;
  height: 350px;
 }
}
