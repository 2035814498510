.places-title {
  background-color: rgb(255, 54, 54);
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

.search { font-size: 25px; }

.filter {
  margin-bottom: 3%;
  font-family: 'Gilroy-Bold';
}

.filter-top-row { margin-bottom: 20px; }

.map-container { max-width: 100%; }

.places-display {
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap; 
}

.place-list {
  flex-basis: 50%; 
  margin-right: 2%;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

.places-loading {
  font-size: 40px;
  font-family: 'Gilroy-Bold';
}

.loading-circle {
  height: auto;
  width: 400px;
  margin-bottom: 100px;
}

.entry-format {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
}

.place-info { width: 60%; }

.web-view { font-size: 25px; }

.name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.place-rating {
  display: flex;
  align-items: center;
}

.num-ratings { margin-left: 10px; }

.place-link { color: rgb(142, 227, 255); }

.place-image {
  height: auto;
  width: 40%;
}

.img {
  width: 100%;
  height: 100%;
}

.place-entry {
  background-color: rgb(17, 17, 17);
  border: 1px solid black;
  border-radius: 5px;
  padding: 1%;
  margin-bottom: 5vh;
  width: 100%;
}

.h-b5 {
  color: black;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  border: 1px solid black;
}

.h-b5:hover {
  background-color: rgb(212, 212, 212);
}

@media (min-width: 768px) {
  .only-on-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .places-title {
    height: 490px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mr1 { font-size: 15px;}

  .mrp1 {
    font-size: 18px;
  }

  .search {
    font-size: 20px;
  }

  .filter {
    text-align: center;
    font-size: 50px;
    margin-bottom: 25px;
  }

  .filter-top-row {
    margin-bottom: 0;
  }

  .places-display {
    flex-direction: column;
    align-items: center;
    margin: 0 5% 0 5%;
  }

  .place-list {
    flex-basis: 100%;
  }

  .entry-format {
    flex-direction: column;
    align-items: center;
  }

  .name {
    font-size: 25px;
  }

  .address {
    font-size: 20px;
  }

  .place-info {
    width: 100%;
    text-align: center;
  }

  .place-image {
    margin-left: 0;
    margin-top: 15px;
    height: auto;
    width: 90%;
  }

  .place-rating {
    justify-content: center;
  }

  .num-ratings {
    margin-right: 0;
  }

  .img {
    max-height: 200px;
  }
}