.footer {
  background-color: black;
  font-family: "readexpro";
  padding: 50px 0;
  color: white;
  text-align: center;
}
  
.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 960px;
  margin: 0 auto;
}

.footer-column ul {
  padding: 0;
  margin: 0;
}

.footer-link {
  font-size: 20px;
}

.footer-line {
  text-align: left;
  padding-top: 10px;
  padding-left: 50px;
  font-size: 15px;
  margin-top: 20px;
}

.privacy {
  text-align: left;
  font-size: 15px;
}

.policies {
  margin: 10px 0 0 35px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-link {
    font-size: 15px;
  }
}