.placepage-title {
  background-color: rgb(0, 0, 0);
  background-image: url('../images/ghost-background1.png');
  flex-direction: row;
  align-items: center;
  padding-top: 1%;
} 

.title-image-section { margin-right: 5%; }

.title-image {
  max-width: 700px;
  max-height: 500px;
}

.disclaimer {
  display: flex;
  margin: 3% 10% 0 0;
}

.back-button { margin-right: 5%; }

.h-b6 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 50px;
}

.placepage-middle {
  background-color: black;
  background-image: url('../images/ghost-background1.png');
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 40px 0 40px 0;
} 

.place-options { display: flex; }

.modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-font { font-size: 30px; }

.description-field {
  width: 80%;
  height: 150px;
  font-family: sans-serif;
  font-size: 25px;
}

.rp3 { font-size: 45px; }

.review-list { margin-left: 10%; }
 
.username { 
  font-size: 25px; 
}

.rating-top-portion {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.ghosts-color { background-color: red; }
.aliens-color {background-color: orange; }
.cryptids-color { background-color: blue; }
.unexplained-color { background-color: green; }

.page-ratings {
  display: flex;
  justify-content: space-between;  
}

.scariness-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h-b3a { margin-right: 50px; }

.review-entry {
  box-shadow: 3px 4px #9b9b9b;
  background-color: rgb(255, 255, 255); 
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  padding: 1%;
  margin-bottom: 5vh;
  width: 75%;
}

.image-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.review-img {
  max-width: 100%; 
  max-height: 300px; 
  display: block;
  margin: 0 auto; 
  object-fit: contain;
}

.image-popup {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.date { font-size: 23px; }

.mr { margin-right: 20px; }

.ml { margin-left: 20px; }

.h-b3 {
  color: white;
  background-color: red;
  border-radius: 10px;
  border: none;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}

.h-b3:hover { background-color: rgb(255, 79, 79); }

.h-b4 {
  color: black;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
}

.h-b4:hover { background-color: rgb(255, 205, 205); }

.h-b4.active {
  background-color: red;
  color: white;
  border: none;
}

.vote {
  background-color: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .back-button {
    display: flex;
    justify-content: flex-start;
  }

  .placepage-title {
    flex-direction: column;
    padding: 10px;
  }

  .title-image {
    max-height: 300px;
    max-width: 100%;
  }

  .disclaimer {
    font-size: 11px;
    margin: 0;
    text-align: center;
  }

  .h-b3a { margin-right: 0; }

  .mobile-name { text-align: center; }

  .mobile-add {
    text-align: center;
    font-size: 20px;
  }

  .mobile-web {
    display: flex;
    justify-content: center;
    color: aqua;
    font-size: 20px;
  }

  .mobile-rating { font-size: 20px; }

  .mobile-review { font-size: 30px; }

  .scariness-rating {
    margin-right: 0px;
    font-size: 30px;
    flex-direction: column;
  }

  .title-image-section {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .place-options {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-form {
    display: flex;
    text-align: center;
    padding-top: 10px;
  }

  .modal-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-font { font-size: 25px; }

  .description-field {
    width: 90%;
    height: 120px;
    font-size: 20px;
  }

  .review-list {
    margin-left: 0%;
    padding: 0 3%;
  }

  .review-entry {
    width: 95%;
    padding: 10px;
  }
  
  .username { font-size: 20px; }

  .image-display {
    flex-direction: column;
  }

  .review-img { max-height: 200px; }

  .image-popup {
    height: 100%;
  }

  .rotated-image {
    transform: rotate(90deg);
  }

  .filters { flex-direction: column; }

  .mobile-filter:nth-child(-n + 3) { flex-basis: 33.33%; }

  .date {
    font-size: 16px;
    padding-top: 5px;
  }

  .mr {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .ml {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .vote { font-size: 25px; }
}

  