.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: black;
  background-image: url('../images/ghost-background1.png');
  background-repeat: repeat;
  animation: scrollBackground 20s linear infinite; 
  }
  
@keyframes scrollBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
  
.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  height: 400px;
  width: 800px;
  text-align: center;
}

.auth-text {
  font-size: 25px;
  font-family: 'Gilroy-Bold';
}
  
.google-button {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(-45deg, white 50%, #5e9cff 50%);
  background-position: 100%;
  background-size: 400%;
  padding: 12px 24px;
  font-size: 25px;
  border: 4px solid #174083 ;
  border-radius: 10px;
  transition: background-position 0.5s;
}
  
.google-button:hover {
  background-position: 0%;
  cursor: pointer;
}
  
.google-icon {
  height: 50px;
  width: 50px;
}
  
  /* Mobile devices */
  @media (max-width: 767px) {
    .auth {
      height: 80vh;
    }

    .auth-text { font-size: 20px; }
  
    .auth-container {
      width: 80%; 
      height: 60%; 
      padding: 15px; 
    }
  
    .google-button {
      font-size: 15px; 
      padding: 8px 15px; 
    }
  }
  
  /*Tablets */
  @media (min-width: 768px) and (max-width: 1024px) {
    .auth {
      height: 70vh; 
    }
  
    .auth-container {
      width: 80%; 
      height: auto; 
      padding: 15px; 
    }
  
    .google-button {
      font-size: 15px; 
      padding: 12px 22px;
    }
  }
  