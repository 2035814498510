.home-categories {
  background: rgb(0,0,0);
  background-image: url('../../images/ghost-background1.png');
  padding-top: 150px;
  padding-bottom: 50px;
  align-items: center;
}

.test {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
}

.b2 { font-size: 70px; }

.h-b2 {
  border-radius: 15px 15px 15px 15px;
  margin-bottom: 20px;
  color: white;
  padding: 0 35px 0 35px;
  position: relative;
  transition: all 0.5s ease;
}

.hc { width: 600px; }

.fade-right { animation: fadeRight 1s ease-out forwards; }
  
@keyframes fadeRight {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0%);
  }
}
  
@keyframes shake {
  0% { transform: translate(0, 0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-5px, 0); }
  20%, 40%, 60%, 80% { transform: translate(5px, 0); }
  100% { transform: translate(0, 0); }
}

.h-b2a { background-color: red; }
.h-b2b { background-color: orange; }
.h-b2c { background-color: blue; }
.h-b2d { background-color: green; }


@media screen and (max-width: 768px) {
  .home-categories {
    padding-top: 100px;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }

  .test {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .hc {
    width: 100%;
    max-width: 300px;
  }

  .home-categories-title { text-align: center; }

  .b2 { font-size: 30px; width: 250px; }

  .h-b2 {
    border-radius: 10px;
    padding: 5px;
  }

  .fade-right { animation: fadeRightMobile 1s ease-out forwards; }

  @keyframes fadeRightMobile {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
}


