.navbar {
  margin: 0;
  width: 100%;
  height: 90px;
  background-color: rgb(15, 15, 15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

.navbar .left-links {
  margin-left: 1%;
  font-size: 40px;
}

.navbar .left-links:hover {
  transform: scaleX(-1);
}

.navbar .main-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.navbar .main-links a {
  margin: 0 30px;
  position: relative;
  transition: color 0.3s;
}

.navbar .main-links a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.navbar .right-links {
  margin-right: 1%;
}

.navbar a.active::before {
  background-color: #ff0000; 
}

.navbar a:hover::before {
  background-color: #ff0000; 
}

.navbar a.active,
.navbar a:hover {
  color: #ff0000; 
  border-color: red;
}

.login {
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px 10px;
}

/* Hamburger menu styles */
.hamburger {
  display: none; 
  cursor: pointer;
  align-items: center;
}

.bar {
  height: 3px;
  background-color: white;
  margin: 10px 0;
  width: 40px;
  transition: width 0.5s, background-color 1s;
}

.hamburger.open .bar { background-color: red; }
.hamburger.open .bar1 { width: 40px; }
.hamburger.open .bar2 { width: 30px; }
.hamburger.open .bar3 { width: 20px; }
.left-links.open { color: red; }

/* Mobile menu styles */
.mobile-menu {
  display: none; 
}

/* Show the mobile menu when it's open */
.mobile-menu.open {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 90px;
  right: 0;
  background-color: rgb(26, 26, 26);
  width: 100%;
  padding: 20px;
  z-index: 1;
}


@media screen and (max-width: 768px) {
  .hamburger {
    display: block; 
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .main-links,
  .right-links {
    display: none !important;
  }

  .navbar {
    position: relative; 
  }

  .navbar .left-links {
    margin-right: 80%;
    font-size: 35px;
  }

  .mobile-menu.open {
    justify-content: flex-end;
  }
}
