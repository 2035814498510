.home-places {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 100px 5% 100px 0;
}

.moving-ghost {
    opacity: 0;
    background-color: white;
    height: 300px;
    width: 200px;
    margin: 50px 0px;
}

.home-places-right {
    padding-left: 50px;
}

.sample-place {
    background-color: black;
    height: auto;
    max-width: 300px;
    margin: 50px 1vw;
}

.ls2 {
    background-color: black;
    padding: 30px;
}


.fade-left1 { animation: fadeLeft 4s ease-out forwards; }
.fade-left2 { animation: fadeLeft 4.5s ease-out forwards; }
.fade-left3 { animation: fadeLeft 5s ease-out forwards; }
  
@keyframes fadeLeft {
    from { transform: translateX(800%); }
    50% { transform: translateX(-10%); }
    65% { transform: translateX(5%); }
    80% { transform: translateX(0%); }
    to { transform: translateX(0%); }
}

.fade-left-full { animation: fadeLeftFull 1.8s ease-out forwards; }
    
@keyframes fadeLeftFull {
    from { opacity: 0; transform: translateX(1000%); }
    1% { opacity: 1; }
    50% { opacity: 1; }
    99% { transform: translateX(0%); }
    to { opacity: 0; }
}

.fade-down { animation: fadeDown 3s ease-out forwards; }

@keyframes fadeDown {
    0% { opacity: 0; transform: translateY(-100%); }
    49% { opacity: 0; }
    50% { opacity: 1; transform: translateY(-100%); }
    60% { transform: translateY(0%); }
    to { transform: translateY(0%); }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@media screen and (max-width: 1024px) {
    .fade-left1 { animation: none; }
    .fade-left2 { animation: none; }
    .fade-left3 { animation: none; }
    .fade-down { animation: none; }
    .fade-left-full { animation: none; }

    .fade-in {
        animation: fade-in 3s ease-in-out;
      }

    .left-side {
        align-items: center;
        margin-bottom: 20px;
    }

    .moving-ghost {
        height: 0px;
        width: 0px;
    }

    .ls2 {
        padding: 15px;
        width: 100%;
    }

    .sample-place {
        display: flex;
        height: auto;
        width: 250px;
        align-items: center;
        margin: 10px;
    }
    
    .home-places {
        margin: 0 0 0 0;
    }

    .home-places-right {
        padding-left: 0;
    }
 }

